<template>
  <LargeLayout>
    <div class="container">
      <div class="row body">
        <div class="col-12 login reset-pw">
          <div class="card login-card reset-pw__card">
            <div class="body login-card__body reset-pw__card-body">
              <h1 class="text-left text-175rem">{{ $t("reset-pw.title") }}</h1>
              <h5 class="text-semi-muted">
                {{ $t("reset-pw.enter_your_new_pw") }}
              </h5>
              <b-form @submit.prevent="onSubmit">
                <b-form-group>
                  <b-form-input
                    v-model="newPassword"
                    type="password"
                    :placeholder="$t('reset-pw.new-pw')"
                    @input="hideErr"
                  />
                  <p class="d-none d-lg-block">
                    {{ $t("register.step3.recommend") }}
                  </p>
                  <p class="rs_pw_err" v-if="!$v.newPassword.minLength">
                    {{ $t("reset-pw.validate_pw") }}
                  </p>

                  <b-form-input
                    v-model="confirmPassword"
                    type="password"
                    :placeholder="$t('reset-pw.confirm-pw')"
                    @input="hideErr"
                  />
                  <div
                    class="rs_pw_err"
                    v-if="!$v.confirmPassword.sameAsPassword"
                  >
                    {{ $t("register.step3.identical") }}
                  </div>
                  <p class="text-semi-muted rs_pw_err" v-if="error">
                    {{ $t(`error.${error}`) }}
                  </p>
                  <b-button type="submit" variant="success" class="btn-signin">
                    {{ $t("reset-pw.change_pw") }}
                  </b-button>
                </b-form-group>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </LargeLayout>
</template>

<script>
import { validationMixin } from "vuelidate";
const { required, minLength, sameAs } = require("vuelidate/lib/validators");
import LargeLayout from "../../../layout/main/LargeLayout.vue";
import axiosConfig from "../../../libs/axiosConfig";
import jwtDecode from "jwt-decode";
export default {
  name: "reset-password",
  components: {
    LargeLayout,
  },
  data() {
    return {
      newPassword: "",
      confirmPassword: "",
      message: "",
      error: "",
      message: {
        reset: {
          type: "success",
          title: this.$t("reset-pw.title_toast"),
          text: this.$t("reset-pw.success_msg"),
        },
        link_expired: {
          type: "danger",
          title: this.$t("reset-pw.link_expired_title"),
          text: this.$t("reset-pw.link_expired_msg"),
        },
      },
    };
  },
  mixins: [validationMixin],
  validations: {
    newPassword: {
      required,
      minLength: minLength(6),
    },
    confirmPassword: {
      sameAsPassword: sameAs("newPassword"),
    },
  },

  created() {
    const token = this.$route.params.token;
    if (!token) this.$router.push("/");
    else {
      const exp = jwtDecode(token).exp;
      if (exp < Date.now() / 1000) {
        this.$router.push("/forgot-password");
        setTimeout(() => {
          this.makeToast("link_expired");
        }, 500);
      }
    }
  },
  methods: {
    makeToast(option) {
      this.$bvToast.toast(this.message[option].text, {
        title: this.message[option].title,
        variant: this.message[option].type,
        solid: true,
        toaster: "b-toaster-top-center",
        autoHideDelay: 3000,
      });
    },
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.error = "please_complete_all_fields_correctly";
        return;
      }
      const token = this.$route.params.token;
      await axiosConfig
        .post("/resetPassword", {
          token: token,
          new_password: this.confirmPassword,
        })
        .then((response) => {
          this.$router.push("/login");
          setTimeout(() => {
            this.makeToast("reset");
          }, 500);
        })
        .catch((err) => {
          this.error = err.response.data.detail;
        });
    },
    hideErr() {
      this.error = "";
    },
  },
};
</script>

<style lang="scss">
.or {
  position: relative;
}

.or::after {
  content: "";
  height: 2px;
  width: 100px;
  position: absolute;
  color: var(--success);
  display: block;
  background: var(--success);
  right: 30px;
  top: 50%;
}
.or::before {
  content: "";
  height: 2px;
  width: 100px;
  position: absolute;
  color: var(--success);
  display: block;
  background: var(--success);
  left: 30px;
  top: 50%;
  // z-index: -2;
}

.reset-pw {
  .reset-pw__card {
    .reset-pw__card-body {
      h5 {
        margin-bottom: 16px;
      }
      p {
        margin-bottom: 0px;
      }
      .rs_pw_err {
        color: red;
      }
    }
  }
}
</style>
